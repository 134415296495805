<template>
  <v-container
      id="regular-forms"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-email"
            :title="baseMaterialCardTitle"
            inline
        >
          <template v-if="loading">
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
          </template>
          <v-form v-else>
            <v-row no-gutters v-if="!action">
              <v-col cols="11"></v-col>
              <v-col cols="1" class="text-right align-end mr-0">
                <v-btn small right color="info" class="mr-0"
                       @click="$router.push({name: 'Groups', params: {envCode: envSelected.value} })">
                  <v-icon small class="mr-1">
                    mdi-arrow-left
                  </v-icon>
                  Back
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-5" v-if="!loading && hasRoles">
              <v-col cols="12" md="6" xs="12">
                <v-text-field
                    label="Name *"
                    v-model="group.name"
                    :rules="[() => !!group.name || 'This field is required!']"
                    required
                    counter
                />
              </v-col>
              <v-col cols="12" md="6" xs="12">
                <v-text-field v-if="group.id"
                              label="Code *"
                              v-model="group.code"
                              :disabled="(group.id)?true:false"
                              counter
                />
              </v-col>
              <v-col cols="12" md="12" xs="12">
                <v-text-field
                    label="Description *"
                    v-model="group.description"
                    counter
                />
              </v-col>

              <v-col cols="12" md="12">
                <v-label>
                  Manage Roles
                </v-label>
              </v-col>
            </v-row>

            <v-row v-if="!loading && hasRoles">
              <v-col
                  cols="6"
                  md="6"
                  xs="12"
              >
                <base-material-card
                    color="success"
                    inline
                    text="Attached Permissions"
                >

                  <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="enabledRoles"
                      item-key="priority"
                      disable-pagination
                      hide-default-footer
                  >
                    <template v-slot:body="props">
                      <draggable
                          tag="tbody"
                          v-model="props.items"
                          @change="reorderRoles"
                      >
                        <!-- the row will go here -->
                        <tr
                            v-for="(item, index) in props.items"
                            :key="index"
                        >
                          <td> {{ item.code }}</td>
                          <td> {{ index + 1 }}</td>
                          <td class="text-right">

                            <v-tooltip bottom v-if="fieldAccess.packages">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       v-on="on"
                                       x-small fab color="info">
                                  <v-icon
                                      small
                                      class="page__grab-icon"
                                  >
                                    mdi-arrow-all
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Drag to change order</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="fieldAccess.packages">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-2"
                                    x-small
                                    fab
                                    color="error"
                                    @click="removeEnabledRole(item)"
                                >
                                  <v-icon small>mdi-link-off</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove role from group</span>
                            </v-tooltip>

                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                </base-material-card>
              </v-col>

              <v-col
                  cols="6"
                  md="6"
                  xs="12"
              >
                <base-material-card
                    color="success"
                    inline
                    text="Available Permissions"
                >

                  <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="roles"
                      disable-pagination
                      hide-default-footer
                  >
                    <template v-slot:item.name="{item}">
                      {{ item.code }}
                    </template>

                    <template v-slot:item.order="{ item }">
                      {{ item.order }}
                    </template>

                    <template v-slot:item.actions="{ item }">

                      <v-tooltip bottom v-if="fieldAccess.packages">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              @click="addEnabledRole(item)"
                              color="secondary"
                          >
                            <v-icon small>mdi-link</v-icon>
                          </v-btn>
                        </template>
                        <span>Add role to group</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </base-material-card>
              </v-col>

            </v-row>
            <v-row v-if="!loading && hasRoles" no-gutters>
              <v-col cols="12" class="text-right align-end">
                <v-btn class="mr-2" color="success" @click="updateGroup">
                  <v-icon class="mr-1">mdi-content-save</v-icon>
                  Save
                </v-btn>
                <v-btn class="mr-0" color="error" @click="initialize">
                  <v-icon class="mr-1">mdi-undo</v-icon>
                  Reset
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else class="mt-5">
              <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
                <h2>Oops!</h2>
                <p>
                  It appears you have no ACL Role Permissions Created in this environment.<br/>
                  Therefore you cannot create any Groups.
                </p>
                <p>
                  Head to the <b>Permissions</b> Module from the top right User Menu.<br/>
                  Create some ACL Role Permissions, then come back to this section and you will be able to create/modify
                  Groups.
                </p>
                <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                  <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                  Read the Docks
                </v-btn>
                <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToPermissions">
                  <v-icon small color="white" class="mr-1">mdi-lock</v-icon>
                  open Permissions
                </v-btn>
              </v-alert>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import draggable from "vuedraggable";
import fieldsMixins from "./components/fieldAccess";
import globalMixins from "@/mixins/globalMixins";

export default {
  name: 'GroupsAddEdit',

  components: {
    draggable
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    },
    code: {
      type: String
    },
    action: {
      type: String
    }
  },

  mixins: [globalMixins, fieldsMixins],

  data: () => ({
    fieldAccess: {},
    loading: false,
    hasRoles: false,
    group: {
      name: '',
      description: '',
      packages: {}
    },
    headers: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Priority',
        value: 'order',
      },
      {
        align: 'right',
        text: 'Actions',
        value: 'actions',
      },
    ],
    enabledRoles: [],
    roles: []
  }),

  computed: {
    baseMaterialCardTitle() {
      let label = 'Creating new';
      if (this.id || this.code) {
        label = "Updating";
      }
      if (this.group) {
        return `${label} Group ${this.group.name ? `'${this.group.name}'` : ''}`;
      } else {
        return '';
      }
    }
  },

  created() {
    this.clearMessages();
    this.initialize();
  },

  methods: {

    goToPermissions() {
      this.$router.push({name: 'Roles', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.groups);
    },

    initialize() {
      this.clearMessages();
      this.getGroup(() => {
        this.getRoles(() => {
          this.fixAndRender();
        });
      });
    },

    getGroup(cb) {
      this.group = {
        name: '',
        description: '',
        packages: {}
      };
      this.enabledRoles = [];
      this.loading = true;

      if ((this.id && this.id.trim() !== '') || (this.code && this.code.trim() !== '')) {
        this.fieldAccess = this.groupUpdateAccess();
        let apiOptions;
        if (this.id) {
          apiOptions = {
            noLoading: true,
            url: `/consoleapi/organization/groups/${this.id}`,
            method: "get"
          };
        } else {
          apiOptions = {
            noLoading: true,
            url: `/consoleapi/organization/groups/code/${this.code}`,
            method: "get"
          };
        }

        this.getSendData(apiOptions).then((response) => {
          this.group = response.item;
          this.loading = false;
          return cb();
        });
      } else {
        this.fieldAccess = this.groupCreateAccess();
        this.loading = false;
        return cb();
      }
    },

    getRoles(cb) {
      this.loading = true;
      this.roles = [];

      this.getSendData({
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/roles`,
        method: "get",
        params: {
          fields: ['code', 'name'],
          pagination: false
        }
      }).then((response) => {
        this.roles = response.items;
        if (response.items && response.items.length > 0) {
          this.hasRoles = true;
        }
        this.loading = false;
        return cb();
      });

    },

    fixAndRender() {
      for (let p in this.group.packages) {
        this.enabledRoles.push({code: p, order: this.group.packages[p]});
      }

      this.roles.forEach((oneRole) => {
        oneRole.order = 1;
      });

      for (let i = this.roles.length - 1; i >= 0; i--) {

        let found = false;
        this.enabledRoles.forEach((oneEnabledRole) => {
          if (oneEnabledRole.code === this.roles[i].code) {
            found = true;
          }
        });

        if (found) {
          this.roles.splice(i, 1);
        }
      }
    },

    removeEnabledRole(item) {
      let eRoles = this._lodash.cloneDeep(this.enabledRoles);
      for (let i = eRoles.length - 1; i >= 0; i--) {
        if (eRoles[i].code === item.code) {
          eRoles.splice(i, 1);
        }
      }
      this.enabledRoles = eRoles;

      let found = false;
      this.roles.forEach((oneRole) => {
        if (oneRole.code === item.code) {
          found = true;
        }
      });

      if (!found) {
        let allRoles = this._lodash.cloneDeep(this.roles);
        allRoles.push({
          code: item.code,
          order: 1
        });

        this.roles = allRoles;
      }
    },

    addEnabledRole(item) {
      let found = true;
      for (let i = this.enabledRoles.length - 1; i >= 0; i--) {
        if (this.enabledRoles[i].code !== item.code) {
          found = false;
        }
      }

      if (!found || this.enabledRoles.length === 0) {
        let eRoles = this._lodash.cloneDeep(this.enabledRoles);
        eRoles.push({code: item.code, order: eRoles.length + 1});
        this.enabledRoles = eRoles;
      }

      let roles = this._lodash.cloneDeep(this.roles);
      for (let i = roles.length - 1; i >= 0; i--) {
        if (roles[i].code === item.code) {
          roles.splice(i, 1);
        }
        this.roles = roles;
      }
    },

    updateGroup() {
      const self = this;

      if (!this.group.name || this.group.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'Missing Group name'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.group.description || this.group.description.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'Missing Group description'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.enabledRoles || this.enabledRoles.length === 0) {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'No attached permissions found'
        });
        this.scrollToTop();
        return false;
      }

      this.reRenderRolesArray();

      this.group.packages = {};
      this.enabledRoles.forEach((oneRole) => {
        this.group.packages[oneRole.code] = oneRole.order;
      });

      let payload = {
        name: this.group.name,
        description: this.group.description,
        packages: this._lodash.cloneDeep(this.group.packages)
      };
      if (this.action) {
        this.$emit(this.action, payload);
      } else {
        let apiOptions;
        if (this.group.id) {
          this.filterFields(payload, this.fieldsAcl.group.update);

          apiOptions = {
            url: `/consoleapi/organization/groups/${this.group.id}`,
            method: "patch",
            params: payload
          };
        } else {
          apiOptions = {
            url: `/consoleapi/organization/groups`,
            method: "put",
            params: payload
          };
        }
        self.getSendData(apiOptions)
            .then((response) => {
              self.pushMessage({
                type: 'success',
                title: `Group updated`,
                text: `Group has been updated!`
              });
              //if add redirect to listing
              if (self.group.id) {
                setTimeout(() => {
                  this.initialize();
                }, 2000);
              } else {
                setTimeout(() => {
                  self.goToPage({route: 'Groups', params: {'envCode': self.envSelected.value}});
                }, 2000);
              }
            });
      }

    },

    reorderRoles(event) {
      let tempEroles = this._lodash.cloneDeep(this.enabledRoles);
      if (event && event.moved && event.moved.element) {
        for (let i = tempEroles.length - 1; i >= 0; i--) {
          if (tempEroles[i].code === event.moved.element.code) {
            tempEroles.splice(i, 1);
            break;
          }
        }
        event.moved.element.order = event.moved.newIndex;
        tempEroles.splice(event.moved.newIndex, 0, event.moved.element,)
      }
      this.enabledRoles = tempEroles;

      this.reRenderRolesArray();
    },

    reRenderRolesArray() {
      for (let i = 0; i < this.enabledRoles.length; i++) {
        this.enabledRoles[i].order = i + 1;
      }
    }
  }
}
</script>
<style>
.page__grab-icon:hover {
  cursor: grab;
}
</style>
